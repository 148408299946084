<template>
    <div>
        员工培训
    </div>
</template>

<script>
    export default {
        name: "PerTrain"
    }
</script>

<style scoped>

</style>